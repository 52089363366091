<template>
  <div class="mt-3">
    <div class="table-header my-3">First Purchase Product</div>
    <div class="mb-3 text-right">
      <b-input-group class="w-50 ml-auto">
        <b-form-input
          type="text"
          id="header-search-bar"
          class="search-bar"
          @keyup.enter="handleSearch"
          placeholder="Search Product Name, Product Barcode"
          v-model.trim="filters.search"
        >
        </b-form-input>
        <b-input-group-append is-text>
          <b-iconstack font-scale="2" type="submit" @click="handleSearch">
            <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
          </b-iconstack>
        </b-input-group-append>
      </b-input-group>
    </div>

    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      @sort-changed="table1Sort"
      :sort-by.sync="filterPage.sortBy"
      no-local-sorting
      :sort-desc.sync="filterPage.sortDesc"
      empty-text="No matching records found"
    >
      <template v-slot:cell(price)="data">
        <span> {{ data.item.price | numeral("0,0.00") }}</span>
      </template>
      <template v-slot:cell(quantity)="data">
        <span> {{ data.item.quantity | numeral("0,0") }}</span>
      </template>

      <template v-slot:cell(percent_quantity)="data">
        <span> {{ data.item.percent_quantity }}% </span>
      </template>
      <template v-slot:cell(grand_total)="data">
        <span>
          {{ data.item.grand_total | numeral("0,0.00") }}
        </span>
      </template>
      <template v-slot:cell(action)="data">
        <b-button
          :variant="
            filters.productLv1Id == data.item.product_id
              ? 'custom-primary'
              : 'outline-primary'
          "
          @click="selectFirstProduct(data.item.product_id, 1)"
        >
          {{
            filters.productLv1Id == data.item.product_id
              ? "Selected"
              : "Select Product"
          }}
        </b-button>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <Pagination
      @handleChangeTake="(val) => changePerPage(val, 1)"
      @pagination="(val) => pagination(val, 1)"
      :filter="filterPage"
      take="pageSize"
      :rows="rows"
      :limit="2"
    />
    <template v-if="filters.productLv1Id != 0">
      <div class="table-header my-3">Second Purchase Product</div>
      <b-table
        responsive
        striped
        hover
        :fields="fields2"
        :items="items2"
        :busy="isBusy2"
        show-empty
        @sort-changed="table2Sort"
        :sort-by.sync="filterPage2.sortBy"
        no-local-sorting
        :sort-desc.sync="filterPage2.sortDesc"
        empty-text="No matching records found"
      >
        <template v-slot:cell(price)="data">
          <span> {{ data.item.price | numeral("0,0.00") }}</span>
        </template>
        <template v-slot:cell(quantity)="data">
          <span> {{ data.item.quantity | numeral("0,0") }}</span>
        </template>
        <template v-slot:cell(percent_quantity)="data">
          <span> {{ data.item.percent_quantity | numeral("0,0.00") }}% </span>
        </template>

        <template v-slot:cell(grand_total)="data">
          <span>
            {{ data.item.grand_total | numeral("0,0.00") }}
          </span>
        </template>
        <template v-slot:cell(action)="data">
          <b-button
            :variant="
              filters.productLv2Id == data.item.product_id
                ? 'custom-primary'
                : 'outline-primary'
            "
            @click="selectFirstProduct(data.item.product_id, 2)"
          >
            {{
              filters.productLv2Id == data.item.product_id
                ? "Selected"
                : "Select Product"
            }}
          </b-button>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="(val) => changePerPage(val, 2)"
        @pagination="(val) => pagination(val, 2)"
        :filter="filterPage2"
        take="pageSize"
        :rows="rows2"
        :limit="2"
      />
    </template>
    <template v-if="filters.productLv2Id != 0">
      <div class="table-header my-3">Third Purchase Product</div>
      <b-table
        responsive
        striped
        hover
        :fields="fields2"
        :items="items3"
        :busy="isBusy3"
        show-empty
        @sort-changed="table3Sort"
        :sort-by.sync="filterPage3.sortBy"
        no-local-sorting
        :sort-desc.sync="filterPage3.sortDesc"
        empty-text="No matching records found"
      >
        <template v-slot:cell(price)="data">
          <span> {{ data.item.price | numeral("0,0.00") }}</span>
        </template>
        <template v-slot:cell(quantity)="data">
          <span> {{ data.item.quantity | numeral("0,0") }}</span>
        </template>
        <template v-slot:cell(percent_quantity)="data">
          <span> {{ data.item.percent_quantity | numeral("0,0.00") }}% </span>
        </template>
        <template v-slot:cell(grand_total)="data">
          <span>
            {{ data.item.grand_total | numeral("0,0.00") }}
          </span>
        </template>
        <template v-slot:cell(bills)="data">
          <span>
            {{ data.item.bills | numeral("0,0") }}({{
              data.item.percen_bills
            }}%)
          </span>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="(val) => changePerPage(val, 3)"
        @pagination="(val) => pagination(val, 3)"
        :filter="filterPage3"
        take="pageSize"
        :rows="rows3"
        :limit="2"
      />
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isBusy: false,
      isBusy2: false,
      isBusy3: false,
      fields: [
        {
          key: "barcode",
          label: "Barcode",
          class: "w-1",
          sortable: true,
        },
        {
          key: "product_name",
          label: "Name",
          thClass: "w-3",
          sortable: true,
        },
        {
          key: "price",
          label: "Price",
          class: "w-1",
          sortable: true,
        },
        {
          key: "grand_total",
          label: "Grand Total",
          class: "w-1",
          sortable: true,
        },
        {
          key: "quantity",
          label: "Quantity",
          class: "w-1",
          sortable: true,
        },
        {
          key: "percent_quantity",
          label: "Quantity  %",
          class: "w-1",
        },

        {
          key: "action",
          label: "",
          class: "w-1",
        },
      ],
      fields2: [
        {
          key: "barcode",
          label: "Barcode",
          class: "w-1",
          sortable: true,
        },
        {
          key: "product_name",
          label: "Name",
          thClass: "w-3",
          sortable: true,
        },
        {
          key: "price",
          label: "Price",
          class: "w-1",
          sortable: true,
        },
        {
          key: "grand_total",
          label: "Grand Total",
          class: "w-1",
          sortable: true,
        },
        {
          key: "quantity",
          label: "Quantity",
          class: "w-1",
          sortable: true,
        },
        {
          key: "percent_quantity",
          label: "Quantity  %",
          class: "w-1",
        },
        {
          key: "action",
          label: "",
          class: "w-1",
        },
      ],
      fields3: [
        {
          key: "barcode",
          label: "Barcode",
          class: "w-1",
          sortable: true,
        },
        {
          key: "product_name",
          label: "Name",
          thClass: "w-3",
          sortable: true,
        },
        {
          key: "price",
          label: "Price",
          class: "w-1",
          sortable: true,
        },
        {
          key: "grand_total",
          label: "Grand Total",
          class: "w-1",
          sortable: true,
        },
        {
          key: "quantity",
          label: "Quantity",
          class: "w-1",
          sortable: true,
        },
        {
          key: "percent_quantity",
          label: "Quantity %",
          class: "w-1",
        },
      ],

      items: [],
      items2: [],
      items3: [],
      rows: 0,
      rows2: 0,
      rows3: 0,
      filters: {
        search: "",
        productLv1Id: 0,
        productLv2Id: 0,
      },
      filterPage: {
        page: 1,
        pageSize: 5,
        sortDesc: "",
        sortBy: "",
        SortColumnId: 0, // 1 = barcode, 2 = product name, 3 = price, 4 = grand_total, 5 = quantity
        SortType: false, // ture = ASC, false = DESC
      },
      filterPage2: {
        page: 1,
        pageSize: 5,
        sortDesc: "",
        sortBy: "",
        SortColumnId: 0, // 1 = barcode, 2 = product name, 3 = price, 4 = grand_total, 5 = quantity
        SortType: false, // ture = ASC, false = DESC
      },
      filterPage3: {
        page: 1,
        pageSize: 5,
        sortDesc: "",
        sortBy: "",
        SortColumnId: 0, // 1 = barcode, 2 = product name, 3 = price, 4 = grand_total, 5 = quantity
        SortType: false, // ture = ASC, false = DESC
      },
    };
  },
  created() {
    this.getFirstPurchase();
  },
  methods: {
    async getFirstPurchase() {
      this.isBusy = true;
      let payload = { ...this.filters, ...this.filterPage };
      const result = await this.$report.post(
        "/dashboard/secondpurchase/firstpurchase",
        payload
      );
      this.items = result.data.detail.data;
      this.rows = result.data.detail.total;
      this.isBusy = false;
    },
    async getSecondPurchase() {
      this.isBusy2 = true;
      let payload = { ...this.filters, ...this.filterPage2 };
      const result = await this.$report.post(
        "/dashboard/secondpurchase/secoundpurchase",
        payload
      );
      this.isBusy2 = false;
      this.items2 = result.data.detail.data;
      this.rows2 = result.data.detail.total;
    },
    async getThirdPurchase() {
      this.isBusy3 = true;
      let payload = { ...this.filters, ...this.filterPage3 };
      const result = await this.$report.post(
        "/dashboard/secondpurchase/thirdpurchase",
        payload
      );
      this.items3 = result.data.detail.data;
      this.isBusy3 = false;
      this.rows3 = result.data.detail.total;
    },
    selectFirstProduct(id, lvl) {
      if (lvl == 1) {
        if (this.filters.productLv1Id == id) this.filters.productLv1Id = 0;
        else {
          this.filters.productLv1Id = id;
          this.filters.productLv2Id = 0;
          this.getSecondPurchase();
        }
      } else {
        if (this.filters.productLv2Id == id) this.filters.productLv2Id = 0;
        else {
          this.filters.productLv2Id = id;
          this.getThirdPurchase();
        }
      }
    },
    changePerPage(val, lvl) {
      if (lvl == 1) {
        this.filterPage.page = 1;
        this.filterPage.pageSize = val;
        this.getFirstPurchase();
      } else if (lvl == 2) {
        this.filterPage2.page = 1;
        this.filterPage2.pageSize = val;
        this.getSecondPurchase();
      } else {
        this.filterPage3.page = 1;
        this.filterPage3.pageSize = val;
        this.getThirdPurchase();
      }
    },
    pagination(val, lvl) {
      if (lvl == 1) {
        this.filterPage.page = val;
        this.getFirstPurchase();
      } else if (lvl == 2) {
        this.filterPage2.page = val;
        this.getSecondPurchase();
      } else {
        this.filterPage3.page = val;
        this.getThirdPurchase();
      }
    },
    handleSearch() {
      this.filters.productLv1Id = 0;
      this.filters.productLv2Id = 0;
      this.items2 = [];
      this.items3 = [];
      this.getFirstPurchase();
    },
    table1Sort(ctx) {
      let index = this.fields.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;
      if (
        this.filterPage.SortType &&
        !ctx.sortDesc &&
        this.filterPage.SortColumnId == index
      ) {
        this.filterPage.SortColumnId = 0;
        this.filterPage.sortBy = "";
        this.filterPage.sortDesc = "";
      } else {
        this.filterPage.SortColumnId = index;
        this.filterPage.SortType = ctx.sortDesc;
      }
      this.getFirstPurchase();
    },
    table2Sort(ctx) {
      let index = this.fields.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;
      if (
        this.filterPage2.SortType &&
        !ctx.sortDesc &&
        this.filterPage2.SortColumnId == index
      ) {
        this.filterPage2.SortColumnId = 0;
        this.filterPage2.sortBy = "";
        this.filterPage2.sortDesc = "";
      } else {
        this.filterPage2.SortColumnId = index;
        this.filterPage2.SortType = ctx.sortDesc;
      }
      this.getSecondPurchase();
    },
    table3Sort(ctx) {
      let index = this.fields.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;
      if (
        this.filterPage3.SortType &&
        !ctx.sortDesc &&
        this.filterPage3.SortColumnId == index
      ) {
        this.filterPage3.SortColumnId = 0;
        this.filterPage3.sortBy = "";
        this.filterPage3.sortDesc = "";
      } else {
        this.filterPage3.SortColumnId = index;
        this.filterPage3.SortType = ctx.sortDesc;
      }
      this.getThirdPurchase();
    },
  },
};
</script>

<style>
.table-header {
  font-size: 16px;
  font-weight: bold;
}
</style>
